import React from 'react'

import { ErrorMessageContext } from 'state/context'
import { SetErrorMessage } from 'types'

type Props = {
  children: React.ReactNode
  errorMessage: string | null
  setErrorMessage: SetErrorMessage
}

export const ErrorMessageContextProvider = ({
  children,
  errorMessage,
  setErrorMessage,
}: Props): JSX.Element => {
  return (
    <ErrorMessageContext.Provider value={{ errorMessage, setErrorMessage }}>
      {children}
    </ErrorMessageContext.Provider>
  )
}
