export type Action = () => unknown

export class SuspendedActions {
  private actions: Action[] = []

  add = (action: Action): void => {
    this.actions.push(action)
  }

  resume = (): void => {
    this.actions.forEach(action => action())
    this.actions = []
  }
}
