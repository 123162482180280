import { FC } from 'react'
import classNames from 'classnames'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import { HeaderNav } from 'components/HeaderNav'
import logo from 'components/OnePakLogo/OnePakLogo.svg'
import { MenuConfig } from 'types'
import { persistentRouteDescriptionMap } from 'utils/menuItems'
import { getDefaultPath } from '../../utils/getDefaultPath'
import { useLoggedInAppContext } from 'hooks/useLoggedInAppContext'
import { useScrollPosition } from 'components/Header/hooks/useScrollPosition'

import styles from './Header.module.scss'

export const Header: FC = () => {
  const { user } = useLoggedInAppContext()
  const { menu } = user
  const { pathname } = useLocation()
  const allMenuItems = ([] as MenuConfig[]).concat(...menu.map(item => item.menu || item))
  const pageTitle =
    persistentRouteDescriptionMap[pathname] ||
    allMenuItems.find(({ path }) => path === pathname)?.description

  const scrollPosition = useScrollPosition()
  const defaultPath = getDefaultPath(user)

  return (
    <Navbar
      className={classNames(styles.Header, { [styles.Scrolled]: scrollPosition > 0 })}
      bg="white"
    >
      <LinkContainer to={defaultPath}>
        <Navbar.Brand>
          <img src={logo} className={styles.HeaderLogo} alt="OnePak Logo" />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Collapse className={styles.NavbarCenter}>
        <span className={styles.HeaderText}>{pageTitle}</span>
      </Navbar.Collapse>
      <Navbar.Collapse className={styles.NavbarEnd}>
        <HeaderNav />
      </Navbar.Collapse>
    </Navbar>
  )
}
