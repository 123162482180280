import { FC, ReactNode } from 'react'
import { ErrorMessage, ErrorMessageProps } from 'formik'
import { Form } from 'react-bootstrap'

import styles from './BootstrapFormikFeedback.module.scss'

const BootstrapFeedback: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className={styles.Feedback}>
      <div className="is-invalid" />
      <Form.Control.Feedback type="invalid">{children}</Form.Control.Feedback>
    </div>
  )
}

export const BootstrapFormikFeedback: FC<ErrorMessageProps> = props => {
  return <ErrorMessage component={BootstrapFeedback} {...props} />
}
