import { useEffect } from 'react'

import { getAlertCount } from 'lib/api/alert'
import { CurrentUser } from 'types'
import { PollingState } from 'components/ContextProvider'

export const usePolling = (
  refreshTimestamp: number,
  setPollingState: (state: PollingState) => void,
  user?: CurrentUser,
): void => {
  useEffect(() => {
    if (!user) return

    const { tokenExpired, notificationIntervalMs } = user

    if (tokenExpired) return

    let shouldUpdate = true
    let id: NodeJS.Timeout

    const tick = () => {
      void getAlertCount().then(({ count, version }) => {
        shouldUpdate &&
          setPollingState({
            alertCount: count,
            version,
          })
      })
      id = setTimeout(tick, notificationIntervalMs)
    }
    tick()

    return () => {
      shouldUpdate = false
      clearTimeout(id)
    }
  }, [user, refreshTimestamp, setPollingState])
}
