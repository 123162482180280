const isIeRegExp = /Trident\/7.0; .*rv:\d+[.0-9]+/
const ieLt10VersionRegExp = /; MSIE (\d+)[.0-9]+;/

export const getIeVersion = (userAgent = navigator.userAgent): number | undefined => {
  if (isIeRegExp.test(userAgent)) {
    return 11
  }
  const match = ieLt10VersionRegExp.exec(userAgent)
  return match ? parseInt(match[1]) : undefined
}
