import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { camelCaseKeys } from 'utils/camelCaseKeys'
import { BaseApiResult } from 'types'

export type AuthorizePasswordChangeResponse = BaseApiResult & {
  password_policy: string
  token: string
}

export type AuthorizePasswordChangeResult = BaseApiResult & {
  passwordPolicy: string
  token: string
}

type AuthorizePasswordChange = (uid: string) => Promise<AuthorizePasswordChangeResult>

export const authorizePasswordChange: AuthorizePasswordChange = async uid => {
  const { data } = await api.post<AuthorizePasswordChangeResponse>(ENDPOINTS.user, {
    action: 'authorize_password_change',
    unique_id: uid,
  })

  return camelCaseKeys({ ...data })
}
