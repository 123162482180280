import { useRef } from 'react'
import { Button, Row, Form } from 'react-bootstrap'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Link, useHistory } from 'react-router-dom'

import { sendForgotPasswordEmail } from 'lib/api/user'
import styles from './ForgotPasswordPage.module.scss'
import { FormikInputFormControl } from 'components/FormikInputFormControl'
import { BootstrapFormikFeedback } from 'components/BootstrapFormikFeedback'
import { UNAUTHORIZED_ROUTES } from 'Routes'
import { useUnauthorizedErrorMessageContext } from '../../hooks/useUnauthorizedErrorMessageContext'

const ForgotPasswordValidation = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email address')
    .required('Please enter your email address'),
})

interface ForgotPasswordPageParams {
  email: string
}

export const ForgotPasswordPage = (): JSX.Element => {
  const { setErrorMessage } = useUnauthorizedErrorMessageContext()

  const history = useHistory()
  const emailRef = useRef<HTMLInputElement | null>(null)

  const onSubmit = async (values: ForgotPasswordPageParams) => {
    try {
      setErrorMessage(null)
      await sendForgotPasswordEmail(values)
      history.push(UNAUTHORIZED_ROUTES.forgotPasswordSuccess, {
        email: values.email,
      })
    } catch (error) {
      setErrorMessage((error as Error).message)
    }
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmit}
      validationSchema={ForgotPasswordValidation}
    >
      {({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <h2 className={styles.PromptHeading}>Forget your password?</h2>
            <p className={styles.Prompt}>Enter your email and we&rsquo;ll send the instructions.</p>
            <Form.Group controlId="username">
              <Form.Label>Enter email address:</Form.Label>
              <FormikInputFormControl name="email" placeholder="Your email" ref={emailRef} />
              <BootstrapFormikFeedback name="email" />
            </Form.Group>
            <Row className="justify-content-center mb-3">
              <Link to={UNAUTHORIZED_ROUTES.login}>Back to Sign in</Link>
            </Row>
            <Row className="justify-content-center">
              <Button type="submit">Send Reset Instructions</Button>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}
