import { AppContext, ContextInterface } from 'state/context'
import { useContext } from 'react'

type UseAppContext = () => ContextInterface

export const useAppContext: UseAppContext = () => {
  const appContext = useContext(AppContext)

  if (appContext === undefined) {
    throw new Error('useAppContext must be used inside ContextProvider')
  }

  return appContext
}
