import { ClientError } from 'lib/api/ClientError'
import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { CurrentUser } from 'types'
import { formatUserResponse, UserAuthResponse } from 'lib/api/user/utils/formatUserResponse'

export type LogInResponse = UserAuthResponse

interface LogInArgs {
  password: string
  username: string
}

export const logInConstants = {
  action: 'login',
}
export const logIn = async ({ password, username }: LogInArgs): Promise<CurrentUser | void> => {
  try {
    const { data } = await api.post<LogInResponse>(ENDPOINTS.user, {
      ...logInConstants,
      password,
      user_name: username,
    })

    return formatUserResponse(data)
  } catch (e) {
    if ((e as any).response) {
      throw new ClientError((e as any).response.data.error)
    } else {
      throw e
    }
  }
}
