import { FC } from 'react'

export const EmailIcon: FC = () => (
  <svg height="29" viewBox="0 0 29 29" width="29" xmlns="http://www.w3.org/2000/svg">
    <g fill="none">
      <path
        d="m29 14.5c0 8.008-6.492 14.5-14.5 14.5s-14.5-6.492-14.5-14.5 6.492-14.5 14.5-14.5 14.5 6.492 14.5 14.5"
        fill="#3498db"
      />
      <path
        d="m16.856 13.972 7.099 6.12.062-.015c.025-.217.074-.433.074-.65.005-3.084.004-6.168.004-9.253 0-.437.005-.874-.001-1.31-.004-.31-.08-.598-.308-.847zm-11.562-5.899c-.041.248-.102.442-.102.636-.006 3.712-.004 7.424-.004 11.136.001.091.011.182.02.318l7.382-6.203zm.69-.477-.004.05c.042.037.084.075.128.11 2.587 2.091 5.174 4.183 7.762 6.273.551.444 1.295.434 1.834-.03 2.427-2.085 4.849-4.176 7.274-6.264.042-.035.094-.058.141-.086l-.038-.053zm17.454 13.116.003-.08-7.146-6.16c-1.055.85-2.09.824-3.12-.028-2.488 2.092-4.972 4.18-7.456 6.266l.017.055c.148.022.295.062.442.062 5.52.004 11.04.004 16.56-.002.233 0 .466-.074.7-.113zm-8.93.88h-8.29c-.728 0-1.298-.282-1.612-.956-.107-.229-.172-.499-.172-.75-.01-3.738-.012-7.475-.005-11.213.003-.886.543-1.573 1.382-1.805.169-.046.35-.058.526-.058 5.483-.003 10.964-.004 16.446-.002 1.2.001 2.073.88 2.074 2.08v10.605c-.001 1.222-.87 2.096-2.098 2.097-2.751.004-5.501.001-8.251.001z"
        fill="#fff"
      />
    </g>
  </svg>
)
