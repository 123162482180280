import * as React from 'react'
import { debounce } from 'lodash'

export const useScrollPosition = (): number => {
  const [scrollPosition, setScrollPosition] = React.useState(0)

  React.useEffect(() => {
    const scrollHandler = debounce(() => {
      setScrollPosition(window.scrollY)
    }, 10)

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return scrollPosition
}
