import { AxiosPromise } from 'axios'

import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'
import { snakeCaseKeys } from 'utils/snakeCaseKeys'

type ChangePasswordParams = {
  newPassword: string
  oldPassword?: string
  token?: string
}
type ChangePassword = (params: ChangePasswordParams) => AxiosPromise<BaseApiResult>

export const changePassword: ChangePassword = params => {
  return api.post<BaseApiResult>(ENDPOINTS.user, {
    action: 'change_password',
    ...snakeCaseKeys(params),
  })
}
