import { FC } from 'react'

import { EmailIcon } from 'components/EmailIcon'
import styles from './ForgotPasswordSuccessPage.module.scss'
import { useLocationParam } from 'hooks/useLocationParam'

export const ForgotPasswordSuccessPage: FC = () => {
  const [email] = useLocationParam<string>('email')

  return (
    <div className={styles.MessageContainer}>
      <div className={styles.IconColumn}>
        <EmailIcon />
      </div>
      <div className={styles.MessageColumn}>
        <h2 className={styles.MessageHeading}>Email Sent</h2>
        <p className={styles.Message}>
          An email has been sent to <strong>{email}</strong> with further instructions.
        </p>
      </div>
    </div>
  )
}
