const passwordLengthOK = (password: string): boolean => {
  const minLengthRegex = /[^\s]{8,}/
  return minLengthRegex.test(password)
}

export const passwordValidator = (password: unknown): boolean => {
  const passwordStr = password as string

  const atLeastOneUpperCaseAlphaRegex = /(?=.*?[A-Z])/
  const atLeastOneLowerCaseAlphaRegex = /(?=.*?[a-z])/
  const atLeastOneNumberRegex = /(?=.*?[0-9])/
  const atLeastOneSymbolRegex = /(?=.*?[`~!@#$%^&*()_+{}|:"<>?[\]\\;',./])/

  const testsArray = [
    atLeastOneUpperCaseAlphaRegex,
    atLeastOneLowerCaseAlphaRegex,
    atLeastOneNumberRegex,
    atLeastOneSymbolRegex,
  ]
  const testsThresholdToPass = 3
  let testsPassed = 0

  if (passwordLengthOK(passwordStr)) {
    testsArray.forEach(regex => {
      if (regex.test(passwordStr)) testsPassed++
    })
  }
  return testsPassed >= testsThresholdToPass
}
