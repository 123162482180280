import { FC } from 'react'

import { AccountManagementHeaderLinks } from 'components/AccountManagementHeaderLinks'
import { BellIconWrapper } from './BellIconWrapper'
import { HeaderDropdown } from 'components/HeaderDropdown'
import { HeaderLink } from 'components/HeaderLink'
import { useLoggedInAppContext } from 'hooks/useLoggedInAppContext'
import { useAppContext } from 'hooks/useAppContext'

export const HeaderNav: FC = () => {
  const {
    user: { menu },
  } = useLoggedInAppContext()

  const { alertCount } = useAppContext()

  const menuItems = menu.map(({ path, menu, menuId, description, icon }) => {
    if (menu && icon !== 'user_account')
      return <HeaderDropdown key={menuId} label={description} menu={menu} />
    if (menu && icon === 'user_account')
      return <AccountManagementHeaderLinks key={path} menu={menu} />
    return <HeaderLink key={menuId} to={path} label={description} />
  })

  return (
    <>
      {menuItems}
      {alertCount > 0 && <BellIconWrapper />}
    </>
  )
}
