import { useContext } from 'react'

import { ErrorMessageContext } from 'state/context'
import { SetErrorMessage } from 'types'

type ErrorContext = {
  errorMessage: string | null
  setErrorMessage: SetErrorMessage
}

export const useErrorMessageContext = (): ErrorContext => {
  const errorContext = useContext(ErrorMessageContext)

  if (errorContext === undefined) {
    throw new Error('useErrorMessageContext must be used inside ErrorMessageContextProvider')
  }

  return errorContext
}
