import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { CurrentUser } from 'types'
import { UserAuthResponse, formatUserResponse } from 'lib/api/user/utils/formatUserResponse'

export type AuthorizationCheckResponse = Omit<UserAuthResponse, 'token'>
type AuthorizationCheck = (params: { token: string }) => Promise<CurrentUser>
export const authorizationCheck: AuthorizationCheck = async ({ token }) => {
  const { data } = await api.get<AuthorizationCheckResponse>(ENDPOINTS.user, {
    params: {
      action: 'check_auth',
      token,
    },
  })
  return formatUserResponse({ ...data, token })
}
