import { FC, lazy as reactLazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import {
  alertsPath,
  createNewOrder,
  dashboardPath,
  dispatchSearchPath,
  logoutPath,
  myAccountPath,
  orderDetailsPath,
  ottoPath,
  rootPath,
} from './Routes'

import { ErrorBoundary } from './ErrorBoundary'
import { ExpiredPasswordForm } from '../ExpiredPasswordForm'
import { ForgotPasswordPage } from '../ForgotPasswordPage'
import { ForgotPasswordSuccessPage } from '../ForgotPasswordSuccessPage'
import { Header } from '../Header'
import { ResetPasswordPage } from '../ResetPasswordPage'
import { LogInPage } from '../LogInPage'
import { LogInModal } from '../LogInModal'
import { Page } from 'components/Page'
import { ProtectedRoute } from '../ProtectedRoute'
import { UNAUTHORIZED_ROUTES } from '../../Routes'
import { UnauthorizedHeader } from '../UnauthorizedHeader'
import { UnauthorizedPage } from '../UnauthorizedPage'
import { UnAuthorizedResetPWSuccess } from '../UnauthorizedResetPWSuccess'

const unauthorizedHeader = () => <UnauthorizedHeader />
const authorizedHeader = () => <Header />

const lazy: typeof reactLazy = fn => {
  return reactLazy(() =>
    fn().catch(error => {
      if (error.name === 'ChunkLoadError') window.location.reload()
      throw error
    }),
  )
}

const AlertsPage = lazy(() => import('pages/AlertsPage'))
const CreateAccountPage = lazy(() => import('pages/CreateAccountPage'))
const CreateNewOrderPages = lazy(() => import('pages/CreateNewOrderPages'))
const DispatchesPage = lazy(() => import('pages/DispatchesPage'))
const DispatchSearchResultsPage = lazy(() => import('pages/DispatchSearchResultsPage'))
const LogoutPage = lazy(() => import('pages/LogoutPage'))
const MyAccountPage = lazy(() => import('pages/MyAccountPage'))
const OrderDetailsPage = lazy(() => import('pages/OrderDetailsPage'))
const OttoPage = lazy(() => import('pages/OttoPage'))

const LoadingPage: FC = () => <Page renderHeader={unauthorizedHeader} />

export const Router = (): JSX.Element => (
  <BrowserRouter basename={import.meta.env.PUBLIC_URL}>
    <ErrorBoundary>
      <Suspense fallback={<LoadingPage />}>
        <LogInModal />
        <Switch>
          <Route exact path={UNAUTHORIZED_ROUTES.createAccount}>
            <CreateAccountPage />
          </Route>
          <Route exact path={UNAUTHORIZED_ROUTES.login}>
            <LogInPage />
          </Route>

          <Route exact path={UNAUTHORIZED_ROUTES.passwordExpired}>
            <UnauthorizedPage>
              <ExpiredPasswordForm />
            </UnauthorizedPage>
          </Route>

          <Route exact path={UNAUTHORIZED_ROUTES.forgotPassword}>
            <UnauthorizedPage>
              <ForgotPasswordPage />
            </UnauthorizedPage>
          </Route>

          <Route exact path={UNAUTHORIZED_ROUTES.forgotPasswordSuccess}>
            <UnauthorizedPage>
              <ForgotPasswordSuccessPage />
            </UnauthorizedPage>
          </Route>

          <Route exact path={`${UNAUTHORIZED_ROUTES.resetPassword}/:uid`}>
            <UnauthorizedPage>
              <ResetPasswordPage />
            </UnauthorizedPage>
          </Route>

          <Route exact path={UNAUTHORIZED_ROUTES.resetPasswordSuccess}>
            <UnauthorizedPage>
              <UnAuthorizedResetPWSuccess />
            </UnauthorizedPage>
          </Route>

          <ProtectedRoute path={alertsPath}>
            <AlertsPage />
          </ProtectedRoute>
          <ProtectedRoute path={createNewOrder}>
            <CreateNewOrderPages />
          </ProtectedRoute>
          <ProtectedRoute path={dashboardPath} exact>
            <DispatchesPage />
          </ProtectedRoute>
          <ProtectedRoute path={dispatchSearchPath} exact>
            <DispatchSearchResultsPage />
          </ProtectedRoute>
          <ProtectedRoute path={myAccountPath} exact>
            <MyAccountPage />
          </ProtectedRoute>
          <ProtectedRoute path={orderDetailsPath()} exact>
            <OrderDetailsPage />
          </ProtectedRoute>
          <ProtectedRoute path={ottoPath} exact>
            <OttoPage />
          </ProtectedRoute>
          <ProtectedRoute path={rootPath} exact>
            <MyAccountPage />
          </ProtectedRoute>

          <Route path={logoutPath} exact>
            <LogoutPage />
          </Route>

          <ProtectedRoute>
            <Page renderHeader={authorizedHeader}>
              <h1>Page Not Found</h1>
            </Page>
          </ProtectedRoute>
          <Route>
            <Page renderHeader={unauthorizedHeader}>
              <h1>Page Not Found</h1>
            </Page>
          </Route>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  </BrowserRouter>
)
