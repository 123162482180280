import { AxiosPromise } from 'axios'

import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseApiResult } from 'types'

type Params = {
  errorMessage: string
  identifier1?: number | string
  identifier2?: number | string
  identifierType1?: string
  identifierType2?: string
  token?: string
}

type Report = (params: Params) => AxiosPromise<BaseApiResult>

export const report: Report = ({
  errorMessage,
  identifier1,
  identifier2,
  identifierType1,
  identifierType2,
  token,
}) => {
  return api.post(ENDPOINTS.error, {
    action: 'report',
    error_message: errorMessage,
    identifier1,
    identifier2,
    identifier_type1: identifierType1,
    identifier_type2: identifierType2,
    menu_id: '0',
    token,
  })
}
