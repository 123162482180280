import { AxiosPromise } from 'axios'

import { api } from 'lib/api/base'
import { BaseApiResult } from 'types'
import { ENDPOINTS } from 'lib/api/endpoints'

type Params = {
  email: string
}
type SendForgotPasswordEmail = (params: Params) => AxiosPromise<BaseApiResult>
export const sendForgotPasswordEmail: SendForgotPasswordEmail = ({ email }) => {
  return api.post(ENDPOINTS.user, {
    action: 'send_forgot_password_email',
    email,
  })
}
