import { FC } from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import style from './HeaderLink.module.scss'

export interface HeaderLinkProps {
  label: string
  to: string
}

export const HeaderLink: FC<HeaderLinkProps> = ({ to, label }) => (
  <LinkContainer to={to} className={style.HeaderLink}>
    <Nav.Link className={style.HeaderLinkText}>
      <span className={style.HeaderLinkText}>{label}</span>
    </Nav.Link>
  </LinkContainer>
)
