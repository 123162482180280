import React from 'react'

import { UnauthorizedErrorMessageContext } from 'state/context'

type Props = {
  children: React.ReactNode
  errorMessage: string | null
  setErrorMessage: (errorMessage: string | null) => void
}

export const UnauthenticatedErrorMessageContextProvider = ({
  children,
  errorMessage,
  setErrorMessage,
}: Props): JSX.Element => (
  <UnauthorizedErrorMessageContext.Provider value={{ errorMessage, setErrorMessage }}>
    {children}
  </UnauthorizedErrorMessageContext.Provider>
)
