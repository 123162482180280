import { useCallback, useState } from 'react'

type UseRefresh = () => [number, () => void]
export const useRefresh: UseRefresh = () => {
  const [lastRefreshed, setLastRefreshed] = useState(Date.now())

  const refresh = useCallback(() => setLastRefreshed(Date.now()), [])

  return [lastRefreshed, refresh]
}
