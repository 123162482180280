import { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { AlertsCountBadge } from 'components/HeaderNav/AlertsCountBadge'
import { ottoAlertPath } from 'components/Router/Routes'
import { TransparentButton } from 'components/TransparentButton'
import styles from './BellIconWrapper.module.scss'

export const BellIconWrapper: FC = () => {
  const history = useHistory()
  return (
    <div className={styles.BellIconWrapper}>
      <TransparentButton
        className={styles.BellIconButton}
        onClick={() => history.push(ottoAlertPath)}
      >
        <AlertsCountBadge />
      </TransparentButton>
    </div>
  )
}
