import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import { createRoot } from 'react-dom/client'

import './index.scss'
import { App } from 'components/App'
import { getIeVersion } from 'utils/getIeVersion'
import { renderBrowserFail } from './renderBrowserFail'
// import * as serviceWorker from './serviceWorker'
const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
const ieVersion = getIeVersion()
if (ieVersion && ieVersion < 11) {
  const el = document.getElementById('root')
  el !== null && (el.innerHTML = renderBrowserFail(ieVersion))
} else if (import.meta.env.NODE_ENV !== 'production' && import.meta.env.VITE_USE_MOCK_API === '1') {
  void import('lib/api/axiosMock').then(() => {
    root.render(<App />)
  })
} else {
  root.render(<App />)
}
