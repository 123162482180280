import { useContext } from 'react'

import { UnauthorizedErrorContext, UnauthorizedErrorMessageContext } from 'state/context'

export const useUnauthorizedErrorMessageContext = (): UnauthorizedErrorContext => {
  const errorContext = useContext(UnauthorizedErrorMessageContext)

  if (errorContext === undefined) {
    throw new Error(
      'useUnauthorizedErrorMessageContext must be used inside UnauthenticatedErrorMessageContextProvider',
    )
  }

  return errorContext
}
