import { FC, ReactNode } from 'react'
import classnames from 'classnames'

import style from './TransparentButton.module.scss'

interface TransparentButtonInterface {
  children?: ReactNode
  className?: string
  onClick: () => void
}

export const TransparentButton: FC<TransparentButtonInterface> = ({
  children,
  onClick,
  className,
  ...props
}) => (
  <button
    type="button"
    className={classnames(style.TransparentButton, className)}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
)
