import { FC, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import { useAppContext } from 'hooks/useAppContext'
import { useCallbackWhenAlertsIncrease } from 'hooks/useCallbackWhenAlertsIncrease'
import { useCallbackWhenUserChanges } from 'hooks/useCallbackWhenUserChanges'
import styles from './AlertsCountBadge.module.scss'

export const AlertsCountBadge: FC<{ shouldAnimate?: boolean }> = ({ shouldAnimate }) => {
  const [animate, setAnimate] = useState(false)
  useEffect(() => {
    if (shouldAnimate !== undefined) setAnimate(shouldAnimate)
  }, [shouldAnimate])
  const { alertCount } = useAppContext()
  const startAnimation = useCallback(() => setAnimate(true), [])
  const stopAnimation = useCallback(() => setAnimate(false), [])
  useCallbackWhenAlertsIncrease(startAnimation)
  useCallbackWhenUserChanges(startAnimation)

  const classes =
    alertCount === 0
      ? classNames(styles.AlertCountBadge, styles.AlertCountBadgeHide)
      : classNames(styles.AlertCountBadge, { [styles.Animate]: animate })

  return (
    <div className={classes} onAnimationEnd={stopAnimation}>
      {alertCount}
    </div>
  )
}
