import { FC } from 'react'

import { OnePakLogo } from 'components/OnePakLogo'
import styles from './Footer.module.scss'

export const Footer: FC = () => {
  return (
    <div className={styles.Footer}>
      <OnePakLogo />
      <p>Copyright &copy; 2006-{new Date().getFullYear()} OnePak Inc. All rights reserved.</p>
    </div>
  )
}
