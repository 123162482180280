import { FC, ReactNode, useEffect } from 'react'
import classNames from 'classnames'
import { Row } from 'react-bootstrap'

import styles from './UnauthorizedPage.module.scss'
import OnePakLogo from 'components/OnePakLogo/OnePakLogo.svg'
import { Page } from 'components/Page'
import { getIeVersion } from 'utils/getIeVersion'
import { BarAlert } from 'components/BarAlert'
import { useUnauthorizedErrorMessageContext } from '../../hooks/useUnauthorizedErrorMessageContext'

export const IE_11_WARNING =
  'We see that you are using Internet Explorer 11. ' +
  'The pages may not display optimally. ' +
  'We recommend using Firefox, Chrome, or Edge.'

export type Props = {
  children?: ReactNode
  inModal?: boolean
}

export const UnauthorizedPage: FC<Props> = ({ children, inModal }) => {
  const { errorMessage, setErrorMessage } = useUnauthorizedErrorMessageContext()

  useEffect(() => {
    getIeVersion() === 11 && setErrorMessage(IE_11_WARNING)
  }, [setErrorMessage])

  return (
    <Page fitOnScreen={false}>
      <Row className={classNames(styles.HeaderLogoContainer)}>
        <img
          src={OnePakLogo}
          className={classNames(styles.HeaderLogo, { [styles.InModal]: inModal })}
          alt="OnePak Logo"
        />
      </Row>

      {errorMessage && <BarAlert onClose={() => setErrorMessage(null)}>{errorMessage}</BarAlert>}

      <Row className={styles.FormRow}>
        <div className={styles.FormContainer}>{children}</div>
      </Row>
    </Page>
  )
}
