import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export const useLocationParam = <T>(key: string): [T | undefined, (value?: T) => void] => {
  const history = useHistory<{ [key: string]: T | undefined }>()
  const [value, setValue] = useState(history.location.state?.[key])

  const updateValue = useCallback(
    (value?: T) => {
      history.replace(history.location.pathname, {
        ...history.location.state,
        [key]: value,
      })
    },
    // No Idea why this complains. It is looking for "T", which is a type.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, key],
  )

  useEffect(() => {
    return history.listen(() => {
      setValue(history.location.state?.[key])
    })
  }, [history, key])

  return [value, updateValue]
}
