import { FC, ReactNode, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import styles from './UnAuthorizedResetPWSuccess.module.scss'
import { GreenDotCheckMarkIcon } from 'components/GreenDotCheckMarkIcon'
import { UNAUTHORIZED_ROUTES } from 'Routes'
import { useAppContext } from '../../hooks/useAppContext'

export type Props = {
  clearExpiredFlag?: () => void
  defaultPath?: string
  setError?: (text: ReactNode) => void
}

export const UnAuthorizedResetPWSuccess: FC<Props> = ({
  clearExpiredFlag,
  defaultPath,
  setError,
}) => {
  const history = useHistory()
  const { setUser } = useAppContext()

  const nextStepCallback = () => {
    clearExpiredFlag && clearExpiredFlag()
    setUser(user => user && { ...user, passwordExpired: false })
    defaultPath ? history.push(defaultPath) : history.push(UNAUTHORIZED_ROUTES.login)
  }

  useEffect(() => {
    setError && setError('')
  }, [setError])

  return (
    <div className={styles.CenterAlignedRow}>
      <GreenDotCheckMarkIcon />
      <h2 className={styles.PromptHeading}>Success!</h2>
      <p className={styles.PromptText}>Your password has been updated.</p>
      <Button onClick={nextStepCallback}>Continue</Button>
    </div>
  )
}
