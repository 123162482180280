import { Modal } from 'react-bootstrap'

import { ClientError } from '../../lib/api/ClientError'
import { FormValues, LogInForm } from '../../forms/LogInForm'
import { logIn } from '../../lib/api/user'
import { UnauthorizedPage } from 'components/UnauthorizedPage'
import { useAppContext } from 'hooks/useAppContext'
import { useUnauthorizedErrorMessageContext } from 'hooks/useUnauthorizedErrorMessageContext'

export const LogInModal = (): JSX.Element => {
  const { setUser, suspendedRetriesRef, user } = useAppContext()
  const { setErrorMessage } = useUnauthorizedErrorMessageContext()

  const handleSubmit = async (values: FormValues) => {
    setErrorMessage(null)

    try {
      const user = await logIn(values)

      if (user) {
        setUser(user)
        suspendedRetriesRef.current.resume()
      }
    } catch (error) {
      if (error instanceof ClientError) {
        setErrorMessage(error.message)
      } else {
        throw error
      }
    }
  }

  return (
    <Modal backdrop="static" centered keyboard={false} show={user?.tokenExpired}>
      <UnauthorizedPage inModal>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <LogInForm onSubmit={handleSubmit} />
      </UnauthorizedPage>
    </Modal>
  )
}
