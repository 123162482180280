import axios, { getAdapter, AxiosAdapter, AxiosRequestConfig, AxiosResponse } from 'axios'

import { buildMemoryStore } from './buildMemoryStore'
import { ENDPOINTS } from '../endpoints'

const defaultAdapter = axios.defaults.adapter
let store = buildMemoryStore<AxiosResponse>()

const destructiveMethods = ['delete', 'patch', 'put', 'post']
const isDestructiveMethod = (method = '') => {
  return destructiveMethods.includes(method.toLowerCase())
}

const getParams = (config: AxiosRequestConfig): Record<string, unknown> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return isDestructiveMethod(config.method) ? JSON.parse(config.data as string) : config.params
}

const getGridConfigCacheKey = (config: AxiosRequestConfig) => {
  const params = getParams(config)
  return `/grid_configs action=get token=${String(params.token)} configId=${String(
    params.config_id,
  )}`
}

if (defaultAdapter === undefined) {
  throw new Error('Adapter not specified for axios')
}

type WithCache = (adapter: AxiosAdapter) => AxiosAdapter
export const withCache: WithCache = adapter => async config => {
  const isGridConfigCall = config.url === ENDPOINTS.gridConfigs

  if (isGridConfigCall && getParams(config).action === 'get') {
    const cachedResponse = await store.get(getGridConfigCacheKey(config))
    if (cachedResponse) return cachedResponse
  }

  const response = await adapter(config)
  if (isGridConfigCall) {
    if (getParams(config).action === 'get') {
      await store.set(getGridConfigCacheKey(config), response)
    } else if (isDestructiveMethod(config.method)) {
      await store.delete(getGridConfigCacheKey(config))
    }
  }

  return response
}

export const cacheAdapter = withCache(getAdapter(defaultAdapter))

export const resetCache = (): void => {
  store = buildMemoryStore<AxiosResponse>()
}
