const renderBrowserFailMessage = (versionNumber: number) =>
  `ReturnCenter Community doesn’t support Internet Explorer ${versionNumber}. ` +
  'We recommend using a current browser such as Firefox, Chrome, or Edge.'

const renderBrowserFailWrapper = (message: string) =>
  '<div style="padding-top: 20%;">' +
  '<div style="border: solid 1px #d0021b; max-width: 775px; margin: 0 auto; padding: 88px 100px; ">' +
  '<p style="font-size: 16px; margin-bottom: 0; text-align: center;">' +
  message +
  '</p>' +
  '</div>' +
  '</div>'

export const renderBrowserFail = (versionNumber: number): string =>
  renderBrowserFailWrapper(renderBrowserFailMessage(versionNumber))
