import { useEffect, useRef } from 'react'

export const useForceUpdate = (version: string, onVersionChange: () => void): void => {
  const initialVersion = useRef(version)

  useEffect(() => {
    if (!initialVersion.current) {
      initialVersion.current = version
    } else if (version !== initialVersion.current) {
      onVersionChange()
      initialVersion.current = version
    }
  }, [onVersionChange, version])
}
